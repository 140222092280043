.product-container:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(122, 122, 122, 0.5);
    border-radius: 2%;
}

.product-container {
    display: inline-block;

    width: 35%;
    height: 40%;
    margin: 50px 15px;
    vertical-align: text-top;
    float: none;
    margin: 5px 0 0 -4px;
    border-style: none;
    padding: 10px;
}

