.app-container {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    flex: 1 0 auto;
}

.app-categories-container {
    display: flex;
    flex-direction: column;
    flex-wrap: none;
}