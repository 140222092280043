footer {
  margin: auto;
    margin-top: 50px;
    background-color: rgb(49, 43, 43);
    color: rgb(241, 235, 235);
    flex: 0 0 auto;
    width: 100%;
    height: fit-content;
  }
  
  .our-contacts {
    text-align: center;
  }
  
  .social-padding {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .footer-row {
    margin: auto;
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .list-footer {
    display: flex;
    width: 35%;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }