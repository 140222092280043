* {
    margin: 0;
    padding: 0;
}

h2 {
    font-weight: 500;
}

body, html {
    font-family: "Montserrat";
    height: 100%;
    color: #222;
    line-height: 1.5;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
    color: black;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}