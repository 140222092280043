.icon-container {
    margin: 0 auto;
    display: block;
    text-align: center;
}

.icon-img {
    margin-bottom: 18px;
    display: inline-block;
    margin: 0 auto;
    display: block;
}
