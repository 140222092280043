.productTxt-container{
    text-align: center;
    display: block;
}

.productTxt-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.27;
    margin-bottom: 14px;
    display: block;
    text-decoration: none !important;
}