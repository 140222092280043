.categories-container {
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    justify-content: space-between;
    list-style: none;
    height: 100%;
}
.categories-container a {
    padding: 7px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    border-left: 10px solid white; 
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1),
         -2px -2px 5px 0 rgba(0,0,0,.1),
        2px 2px 5px 0 rgba(0,0,0,.1),
        -2px 2px 5px 0 rgba(0,0,0,.1);
    font-size: 20px;
    letter-spacing: 2px;
    transition: 0.3s all linear;
}

.categories-container a:nth-child(1){border-color: #1907da;}
.categories-container a:nth-child(2){border-color: #f05d22;}
.categories-container a:nth-child(3){border-color: #9932CC;}
.categories-container a:nth-child(4){border-color: #228B22;}
.categories-container a:nth-child(5){border-color: #1ccfc9;}
.categories-container a:nth-child(6){border-color: #00BFFF;}
.categories-container a:nth-child(7){border-color: #DC143C;}
.categories-container a:nth-child(8){border-color: #00BFFF;}
.categories-container a:nth-child(9){border-color: #9932CC;}
.categories-container a:nth-child(10){border-color: #eded0d;}

.categories-container a:hover {border-left: 10px solid transparent;}
.categories-container a:nth-child(1):hover {border-right: 10px solid #1907da;}
.categories-container a:nth-child(2):hover {border-right: 10px solid #f05d22;}
.categories-container a:nth-child(3):hover {border-right: 10px solid #9932CC;}
.categories-container a:nth-child(4):hover {border-right: 10px solid #228B22;}
.categories-container a:nth-child(5):hover {border-right: 10px solid #1ccfc9;}
.categories-container a:nth-child(6):hover {border-right: 10px solid #00BFFF;}
.categories-container a:nth-child(7):hover {border-right: 10px solid #DC143C;}
.categories-container a:nth-child(8):hover {border-right: 10px solid #00BFFF;}
.categories-container a:nth-child(9):hover {border-right: 10px solid #9932CC;}
.categories-container a:nth-child(10):hover {border-right: 10px solid #eded0d;}

.categories-container .active:nth-child(1) {border-left: 10px solid white; border-right: 10px solid #1907da;}
.categories-container .active:nth-child(2) {border-left: 10px solid white; border-right: 10px solid #f05d22;}
.categories-container .active:nth-child(3) {border-left: 10px solid white; border-right: 10px solid #9932CC;}
.categories-container .active:nth-child(4) {border-left: 10px solid white; border-right: 10px solid #228B22;}
.categories-container .active:nth-child(5) {border-left: 10px solid white; border-right: 10px solid #1ccfc9;}
.categories-container .active:nth-child(6) {border-left: 10px solid white; border-right: 10px solid #00BFFF;}
.categories-container .active:nth-child(7) {border-left: 10px solid white; border-right: 10px solid #DC143C;}
.categories-container .active:nth-child(8) {border-left: 10px solid white; border-right: 10px solid #00BFFF;}
.categories-container .active:nth-child(9) {border-left: 10px solid white; border-right: 10px solid #9932CC;}
.categories-container .active:nth-child(10) {border-left: 10px solid white; border-right: 10px solid #eded0d;}