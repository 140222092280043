.containerr {
    width: 60%;
    margin-left: 50px;
}

.center {
    text-align: center;
}

.ref {
    color: blue;
}